import React, { useEffect, useRef } from 'react'
import cx from 'classnames'

import { useDispatch } from 'react-redux'
import { INITIAL_STATE } from 'store/actions'
import { getWithAuth } from 'lib/api'
import Lottie from 'react-lottie'
import { useLocation } from 'react-router-dom'
import animationData from '../../animations/connecting.json'

import Logo from '../../lib/images/logo-full.png'

const InitialLoading: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()
  const location = useLocation()

  const initialLoad = async () => {
    const token = localStorage.getItem('token')

    if (!token) {
      dispatch({
        type: INITIAL_STATE,
        payload: {
          loading: false,
        },
      })

      return
    }

    try {
      const { data } = await getWithAuth('/me')

      dispatch({
        type: 'INITIAL_STATE',
        payload: {
          loading: false,
          user: {
            authenticated: true,
            ...data,
          },
        },
      })
    } catch {
      localStorage.removeItem('token')

      dispatch({
        type: INITIAL_STATE,
        payload: {
          loading: false,
        },
      })
    }
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const duration = location.pathname === '/event' ? 0 : 2000

    setTimeout(() => {
      const container = ref.current as HTMLDivElement

      container.style.opacity = '0'
    }, duration)

    setTimeout(() => {
      initialLoad()
    }, duration + 250)
  }, [ref])

  return (
    <div
      className="flex flex-col items-center justify-center h-full transition-opacity duration-[250ms]"
      ref={ref}
    >
      <div
        className={cx('w-full flex justify-center', {
          'absolute top-[30px] left-0': location.pathname !== '/event',
        })}
      >
        <img src={Logo} alt="logo" width={200} />
      </div>

      {location.pathname !== '/event' && (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          height={200}
          width={200}
        />
      )}

      {location.pathname !== '/event' && (
        <div className="text-[20px] leading-[1.6] text-dark-500 font-medium">
          {location.pathname.includes('/event')
            ? 'Etkinliğine Bağlanılıyor'
            : 'Kapsülüne Bağlanılıyor'}
        </div>
      )}
    </div>
  )
}

export default InitialLoading
