import cx from 'classnames'
import React from 'react'

import InputMask from 'react-input-mask'

import IconCallBold from 'lib/icons/IconCallBold'
import IconInfoCircleBold from 'lib/icons/IconInfoCircleBold'

type Props = {
  value: string
  defaultValue?: string
  error?: string

  onChange: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void
}

const PhoneInput: React.FC<Props> = (props) => {
  const color =
    (props.value && props.value !== '___ ___ ____') ||
    (props.defaultValue && props.defaultValue !== '___ ___ ____')
      ? 'text-greyscale-900'
      : 'text-greyscale-500'

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value, event)
  }

  return (
    <div className="flex flex-col gap-[8px]">
      <div className="flex text-h6 font-semibold text-greyscale-900">
        Telefon numarası
      </div>
      <div
        className={cx(
          'flex items-center gap-[12px] h-[65px] rounded-[12px] px-[20px]',
          {
            'bg-white border-[2px] border-error': props.error,
            'bg-greyscale-50 border-[2px] border-greyscale-50': !props.error,
          }
        )}
      >
        <div className={cx('flex', color)}>
          <IconCallBold />
        </div>
        <div className="flex-1">
          <InputMask
            mask="999 999 9999"
            className={cx(
              'h-full w-full bg-transparent text-h6 font-regular placeholder:text-greyscale-500 focus:outline-none',
              color
            )}
            placeholder="000 000 0000"
            onChange={onChange}
          />
        </div>
      </div>
      {props.error && (
        <div className="flex items-center bg-transparent-red px-[12px] py-[8px] rounded-[8px] text-error">
          <div className="flex mr-[8px]">
            <IconInfoCircleBold size={18} />
          </div>

          <div className="text-medium">{props.error}</div>
        </div>
      )}
    </div>
  )
}

export default PhoneInput
