import React from 'react'
import { createPortal } from 'react-dom'
import { ModalProps } from '../Agreements/Agreements'

type Props = {
  modal: ModalProps

  setModal: (value: ModalProps | null) => void
}

const Modal: React.FC<Props> = ({ modal, setModal }) =>
  createPortal(
    <div className="fixed top-0 right-0 bottom-0 left-0 w-full h-full z-[999999999999] p-[20px] bg-[rgba(0,0,0,.5)]">
      <div
        className="absolute z-20 top-[10px] right-[10px] w-[30px] h-[30px] bg-[#fff] flex items-center justify-center"
        onClick={() => setModal(null)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          fill="none"
        >
          <path
            d="M11.678 10.17 7.513 6l4.165-4.169a1.092 1.092 0 0 0 0-1.521 1.09 1.09 0 0 0-1.52 0L5.995 4.48 1.829.31a1.086 1.086 0 0 0-1.52 0 1.09 1.09 0 0 0 0 1.521l4.165 4.17L.31 10.17a1.092 1.092 0 0 0 0 1.52 1.09 1.09 0 0 0 1.52 0l4.165-4.17 4.165 4.17a1.09 1.09 0 0 0 1.52 0 1.06 1.06 0 0 0 0-1.52Z"
            fill="#000"
          />
        </svg>
      </div>
      <div className="relative w-full h-full">
        <iframe
          src={modal.source}
          loading="lazy"
          title={modal.title}
          style={{ border: 'none' }}
          className="w-full h-full"
        />
        d
      </div>
    </div>,
    document.body
  )

export default Modal
