import { legacy_createStore as createStore, Store, Action } from 'redux'

import { UserState } from 'lib/types/models'
import { INITIAL_STATE, LOGIN, LOGOUT, VERIFY_ACCOUNT } from './actions'

export interface AppState {
  loading: boolean
  user: UserState
}

interface ActionWithPayload<T> extends Action {
  payload: T
}

const initialState: AppState = {
  loading: true,
  user: {
    authenticated: false,
  },
}

const rootReducer = (
  state: AppState = initialState,
  action: ActionWithPayload<any>
): AppState => {
  switch (action.type) {
    case INITIAL_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case LOGIN: {
      return {
        ...state,
        user: {
          ...action.payload,
          authenticated: true,
        },
      }
    }

    case LOGOUT: {
      return {
        ...state,
        user: {
          authenticated: false,
        },
      }
    }

    case VERIFY_ACCOUNT: {
      return {
        ...state,
        user: {
          ...state.user,
          is_verified: true,
        },
      }
    }

    default:
      return state
  }
}

const store: Store<AppState> = createStore(rootReducer)

export default store
