import cx from 'classnames'
import React, { useRef, useState } from 'react'

import { Input } from 'lib/components/Input'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { ValidationError } from 'lib/types/errors'
import { get, post } from 'lib/api'
import { AxiosError } from 'axios'
import getError from 'lib/utils/getError'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { LOGIN } from 'store/actions'
import Lottie from 'react-lottie'
import { UserGuide } from 'components/UserGuide'
import { Modal } from './components/Modal'
import { onConnectCapsule } from './actions/capsuleActions'
import { onConnectEvent } from './actions/eventActions'
import animationData from '../../animations/waiting.json'
import Logo from '../../lib/images/logo-full.png'

type Mode = 'idle' | 'otp' | 'reset' | 'modal' | 'animation'

const TIME = 4000

const Login: React.FC = () => {
  const loginRef = useRef<HTMLDivElement>(null)
  const animationRef = useRef<HTMLDivElement>(null)

  const [searchParams] = useSearchParams()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [disabled, setDisabled] = useState(false)
  const [mode, setMode] = useState<Mode>('idle')
  const [showGuide, setShowGuide] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const buttonDisabled = !email || !password

  const onEmailChange = (value: string) => {
    setEmail(value)
  }

  const onPasswordChange = (value: string) => {
    setPassword(value)
  }

  const onLogin = async (token: string, userId: number) => {
    if (searchParams.get('capsule')) {
      try {
        await onConnectCapsule({
          token,
          userId,
          navigate,
          capsule: searchParams.get('capsule') as string,
        })
      } catch (e) {
        const error = e as AxiosError

        if (error.response) {
          const data = error.response.data as {
            error: {
              status: number
              message: string
            }
          }

          toast.error(data.error.message)
        }
      }
    } else if (searchParams.get('event')) {
      try {
        await onConnectEvent({
          token,
          userId,
          navigate,
          code: searchParams.get('event') as string,
        })
      } catch (e) {
        const error = e as AxiosError

        if (error.response) {
          const data = error.response.data as {
            error: {
              status: number
              message: string
            }
          }

          toast.error(data.error.message)
        }
      }
    } else {
      navigate('/success')
    }
  }

  const onSubmit = async () => {
    if (buttonDisabled) {
      return
    }

    setLoading(true)
    setErrors([])

    try {
      const { data } = await post('/login', {
        email,
        password,
      })

      if (loginRef.current) {
        loginRef.current.style.opacity = '0'

        setTimeout(() => {
          setMode('animation')
          setTimeout(() => {
            if (animationRef.current) {
              animationRef.current.style.opacity = '1'
            }
          }, 50)
        }, 250)
      }

      setTimeout(() => {
        window.localStorage.setItem('token', data.token)

        dispatch({
          type: LOGIN,
          payload: data.user,
        })

        onLogin(data.token, data.user.id)
      }, TIME + 300)
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          errors: ValidationError[]
        }

        setErrors(data.errors)
      }
    } finally {
      setLoading(false)
    }
  }

  const onBlur = async () => {
    setDisabled(true)

    try {
      const { data } = await get(`/check-user?email=${email}`)

      if (!data.exists) {
        setMode('modal')
      }
    } catch {
    } finally {
      setDisabled(false)
    }
  }

  if (mode === 'animation') {
    return (
      <div
        className="flex flex-col items-center justify-center h-full opacity-0 transition-opacity duration-[250ms]"
        ref={animationRef}
      >
        <div className="w-full absolute top-[30px] left-0 flex justify-center">
          <img src={Logo} alt="logo" width={200} />
        </div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          height={200}
          width={200}
        />

        <div className="text-[20px] leading-[1.6] text-dark-500 font-medium">
          {searchParams.get('capsule') && 'Seni Kapsülünle Eşliyoruz'}
          {searchParams.get('event') && `Seni Event'e Bağlıyoruz`}
        </div>
      </div>
    )
  }

  return (
    <>
      {showGuide && <UserGuide onClose={() => setShowGuide(false)} />}

      <div
        className="flex flex-col h-full transition-opacity duration-[250ms]"
        ref={loginRef}
      >
        <div className="pt-[32px] px-[24px] overflow-scroll flex-1">
          <div className="mb-[32px]">
            <div className="text-greyscale-900 text-h3 font-semibold">
              Tekrar merhaba 👋
            </div>
            {searchParams.get('capsule') && (
              <div
                className="text-[green] text-[15px] font-bold cursor-pointer mt-[16px] underline"
                onClick={() => setShowGuide(true)}
              >
                Kullanım Kılavuzunu izlemek için tıklayın.
              </div>
            )}
          </div>

          <div className="flex flex-col gap-[28px]">
            <div className="flex flex-col gap-[24px]">
              <Input
                value={email}
                onChange={onEmailChange}
                placeholder="E-posta adresi"
                label="E-posta adresi"
                htmlType="email"
                error={getError(errors, 'email')}
                onBlur={onBlur}
              />

              <Input
                value={password}
                onChange={onPasswordChange}
                placeholder="Şifre"
                label="Şifre"
                htmlType="password"
                error={getError(errors, 'password')}
                disabled={disabled}
              />
            </div>

            <div className="flex items-center justify-end">
              <Link
                to={`/forgot-password?capsule=${searchParams.get('capsule')}`}
                className="text-xl text-secondary-800 font-semibold"
              >
                Şifremi Unuttum
              </Link>
            </div>
          </div>
        </div>
        <div className="p-[24px] pb-[32px] border-t border-t-greyscale-100">
          <div
            className={cx(
              'p-[16px] flex justify-center rounded-[100px] text-lg font-semibold relative overflow-hidden',
              {
                'bg-dark-500 text-greyscale-700': buttonDisabled || loading,
                'bg-dark-100 text-white': !buttonDisabled && !loading,
              }
            )}
            onClick={onSubmit}
          >
            {loading && (
              <div className="bg-dark-500 absolute top-0 right-0 bottom-0 left-0 z-10" />
            )}
            {loading && (
              <div className="loading absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] z-[20]">
                <span />
                <span />
                <span />
              </div>
            )}
            Giriş Yap
          </div>
        </div>

        {mode === 'modal' && <Modal />}
      </div>
    </>
  )
}

export default Login
