import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Modal: React.FC = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const onClick = async () => {
    navigate(`/forgot-password?capsule=${searchParams.get('capsule')}`)
  }

  return (
    <div className="absolute top-0 right-0 bottom-0 left-0 z-20 bg-[rgba(0,0,0,.3)] flex items-center justify-center px-[20px]">
      <div className="bg-white rounded-[20px] relative px-[24px] pt-[24px] pb-[48px]">
        <div className="absolute -top-[37.5px] left-[50%] w-[75px] h-[75px] -translate-x-[50%] bg-white rounded-full flex justify-center pt-[10px]">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5252 24.1387C14.9654 25.8706 12.5322 25.8912 10.9575 24.1387"
              stroke="#212121"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7937 20.6975C20.2417 20.6975 23.2246 19.8703 23.5127 16.5502C23.5127 13.2324 21.433 13.4457 21.433 9.37485C21.433 6.19506 18.4191 2.57715 13.7937 2.57715C9.16834 2.57715 6.1544 6.19506 6.1544 9.37485C6.1544 13.4457 4.07471 13.2324 4.07471 16.5502C4.36394 19.8829 7.34689 20.6975 13.7937 20.6975Z"
              stroke="#212121"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="relative z-10">
          <div className="border-b border-b-[#E5E7EB] text-center text-h5 font-semibold text-dark-100 pb-[16px]">
            Hoş geldiniz!
          </div>

          <div className="text-[12px] leading-[1.6] text-dark-100 text-center pt-[16px]">
            Hoş geldiniz! Görünüşe göre eski kullanıcımızsınız! Eski
            kullanıcılarımızın güvenlik ve gizlilik gereği şifrelerini
            değiştirmeleri gerekmektedir.
          </div>

          <div
            onClick={onClick}
            className="absolute w-[calc(100%-64px)] h-[50px] left-[50%] -bottom-[76px] -translate-x-[50%] rounded-full flex items-center justify-center text-[15px] leading-[1.6] font-semibold overflow-hidden bg-dark-100 text-white"
          >
            Tamam
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
