import { AxiosError } from 'axios'
import { post } from 'lib/api'
import IconImageBold from 'lib/icons/IconImageBold'
import IconVideoBold from 'lib/icons/IconVideoBold'
import IconVoiceBold from 'lib/icons/IconVoiceBold'
import React, { useRef } from 'react'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'

type Props = {
  setIsUploading: (isUploading: boolean) => void
  onUpload: (payload: {
    file: File
    type: 'image' | 'video' | 'audio' | 'note'
  }) => void
}

const Upload: React.FC<Props> = ({ setIsUploading, onUpload }) => {
  const imageInputRef = useRef<HTMLInputElement>(null)
  const videoInputRef = useRef<HTMLInputElement>(null)
  const voiceInputRef = useRef<HTMLInputElement>(null)

  const [searchParams] = useSearchParams()

  const onImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file && searchParams.get('uuid')) {
      setIsUploading(true)

      const formData = new FormData()

      formData.append('file', file)
      formData.append('type', 'image')
      formData.append('connection_type', 'event')
      formData.append('connection_id', searchParams.get('uuid') as string)

      try {
        await post('/media/upload', formData)

        onUpload({ file, type: 'image' })
        setIsUploading(false)
        imageInputRef.current!.value = ''
      } catch (e) {
        const error = e as AxiosError

        if (error.response) {
          const data = error.response.data as {
            error: {
              status: number
              message: string
            }
          }

          toast.dismiss()

          toast.error(data.error.message)
        }
      }
    }
  }

  const onVideoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file && searchParams.get('uuid')) {
      setIsUploading(true)

      const formData = new FormData()

      formData.append('file', file)
      formData.append('type', 'video')
      formData.append('connection_type', 'event')
      formData.append('connection_id', searchParams.get('uuid') as string)

      try {
        await post('/media/upload', formData)

        onUpload({ file, type: 'video' })
        setIsUploading(false)
        videoInputRef.current!.value = ''
      } catch (e) {
        const error = e as AxiosError

        if (error.response) {
          const data = error.response.data as {
            error: {
              status: number
              message: string
            }
          }

          toast.dismiss()

          toast.error(data.error.message)
        }
      }
    }
  }

  const onVoiceUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file && searchParams.get('uuid')) {
      setIsUploading(true)

      const formData = new FormData()

      formData.append('file', file)
      formData.append('type', 'audio')
      formData.append('connection_type', 'event')
      formData.append('connection_id', searchParams.get('uuid') as string)

      try {
        await post('/media/upload', formData)

        onUpload({ file, type: 'audio' })
        setIsUploading(false)
        voiceInputRef.current!.value = ''
      } catch (e) {
        const error = e as AxiosError

        if (error.response) {
          const data = error.response.data as {
            error: {
              status: number
              message: string
            }
          }

          toast.dismiss()

          toast.error(data.error.message)
        }
      }
    }
  }

  return (
    <div className="flex justify-between p-[20px] border-t border-greyscale-100 bg-white">
      <div
        className="w-[72px] h-[72px] border border-primary-400 rounded-full flex justify-center items-center text-secondary-900"
        onClick={() => imageInputRef.current?.click()}
      >
        <input
          ref={imageInputRef}
          type="file"
          accept="image/*"
          className="hidden"
          onChange={onImageUpload}
        />
        <IconImageBold size={32} />
      </div>
      <div
        className="w-[72px] h-[72px] border border-primary-400 rounded-full flex justify-center items-center text-secondary-900"
        onClick={() => videoInputRef.current?.click()}
      >
        <input
          ref={videoInputRef}
          type="file"
          accept="video/*"
          className="hidden"
          onChange={onVideoUpload}
        />
        <IconVideoBold size={32} />
      </div>
      <div
        className="w-[72px] h-[72px] border border-primary-400 rounded-full flex justify-center items-center text-secondary-900"
        onClick={() => voiceInputRef.current?.click()}
      >
        <input
          ref={voiceInputRef}
          type="file"
          accept=".wav,.aif,.aiff,.flac,.alac,.aac,.ogg,.mp3"
          className="hidden"
          onChange={onVoiceUpload}
        />
        <IconVoiceBold size={32} />
      </div>
    </div>
  )
}

export default Upload
