import React from 'react'

type Props = {
  size?: number
}

const IconVideoBold: React.FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.15072 6.5H15.8735C19.1028 6.5 21.3575 8.72538 21.3575 11.9146V21.0854C21.3575 24.2746 19.1028 26.5 15.8735 26.5H8.15072C4.92136 26.5 2.66667 24.2746 2.66667 21.0854V11.9146C2.66667 8.72538 4.92136 6.5 8.15072 6.5ZM26.6106 9.67198C27.196 9.37418 27.8827 9.40531 28.4413 9.75726C29 10.1079 29.3333 10.717 29.3333 11.383V21.6178C29.3333 22.2852 29 22.893 28.4413 23.2436C28.136 23.4344 27.7947 23.5319 27.4506 23.5319C27.164 23.5319 26.8773 23.4642 26.6093 23.3275L24.6346 22.3312C23.9039 21.9603 23.4506 21.2158 23.4506 20.3887V12.6107C23.4506 11.7823 23.9039 11.0378 24.6346 10.6696L26.6106 9.67198Z"
      fill="currentColor"
    />
  </svg>
)

export default IconVideoBold
