import React, { useEffect, useState } from 'react'
import { post } from 'lib/api'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'
import { OTPResendButton } from 'lib/components/OTPResendButton'

type Props = {
  phoneNumber: string

  onSuccess: () => void
}

const OTP: React.FC<Props> = ({ phoneNumber, onSuccess }) => {
  const [value, setValue] = useState('')
  const [resendSeconds, setResendSeconds] = useState(60)

  const refs = Array.from({ length: 4 }).map(() =>
    React.useRef<HTMLInputElement>(null)
  )

  const onChange = (index: number) => {
    const newValue = refs
      .map((ref) => ref.current?.value)
      .filter((value) => value)
      .join('')

    setValue(newValue)

    if (refs[index].current) {
      if (refs[index].current?.value) {
        if (index < 3) {
          refs[index + 1].current?.focus()
        }
      } else if (index > 0) {
        refs[index - 1].current?.focus()
      }
    }
  }

  const onResendCode = () => {
    setResendSeconds(60)

    setValue('')

    refs.map((ref) => (ref.current!.value = ''))

    refs[0].current?.focus()

    toast.dismiss()

    toast.success('Kod başarıyla gönderildi.')
  }

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()

    const pastedData = event.clipboardData.getData('text/plain')

    if (pastedData.length > 4) {
      return
    }

    const pastedDataWithoutNonNumericCharacters = pastedData.replace(/\D/g, '')

    setValue(pastedDataWithoutNonNumericCharacters)

    refs.map((ref, index) => {
      if (pastedDataWithoutNonNumericCharacters[index]) {
        ref.current!.value = pastedDataWithoutNonNumericCharacters[index]
      }
    })
  }

  const verify = async () => {
    try {
      await post('/otp/verify', {
        code: value,
        phone_number: phoneNumber,
      })

      onSuccess()
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.dismiss()

        toast.error(data.error.message)
      }
    }
  }

  useEffect(() => {
    if (value.length < 4) {
      return
    }

    verify()
  }, [value])

  useEffect(() => {
    refs[0].current?.focus()
  }, [])

  useEffect(() => {
    if (resendSeconds === 0) {
      return
    }

    const interval = setInterval(() => {
      setResendSeconds((seconds) => seconds - 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <div className="flex flex-col h-full">
      <div className="pt-[32px] px-[24px] overflow-scroll flex-1">
        <div className="mb-[80px]">
          <div className="text-greyscale-900 text-h3 font-semibold">
            Şifreni sıfırla
          </div>
          <div className="text-greyscale-700 text-h6 font-medium">
            {phoneNumber} telefon numarasına gönderdiğimiz tek kullanımlık kodu
            kullanarak şifreni sıfırlayabilirsin.
          </div>
        </div>

        <div className="flex flex-col gap-[40px]">
          <div className="flex justify-between gap-[16px]">
            {Array.from({ length: 4 }).map((_, index) => (
              <div className="flex flex-1 h-[70px]" key={index}>
                <input
                  className="flex-1 w-full h-full bg-greyscale-50 rounded-[12px] focus:bg-primary-900/[.08] outline-none text-center font-bold text-h4 border border-transparent focus:border-dark-500"
                  maxLength={1}
                  ref={refs[index]}
                  onChange={() => onChange(index)}
                  onFocus={() => refs[index].current?.select()}
                  inputMode="numeric"
                  onPaste={onPaste}
                />
              </div>
            ))}
          </div>

          {resendSeconds === 0 && (
            <OTPResendButton
              phone={phoneNumber}
              type="RESET_PASSWORD"
              onSuccess={onResendCode}
            />
          )}
          {resendSeconds > 0 && (
            <div className="text-lg text-greyscale-900 font-semibold text-center">
              Kodu {resendSeconds} saniye içinde yeniden gönderebilirsiniz.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OTP
