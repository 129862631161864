import React, { useState } from 'react'
import { OTP } from './components/OTP'

const VerifyAccount: React.FC = () => {
  const [mode, setMode] = useState<'idle' | 'otp'>('idle')

  const onClick = () => {
    setMode('otp')
  }

  if (mode === 'otp') {
    return <OTP />
  }

  return (
    <div className="flex flex-col h-full">
      <div className="pt-[32px] px-[24px] overflow-scroll flex-1">
        <div className="mb-[32px]">
          <div className="text-greyscale-900 text-h3 font-semibold mb-[16px]">
            Hesabını Doğrula 🔑
          </div>
          <div className="text-greyscale-700 text-h6 font-medium">
            Text to Next uygulamasını kullanabilmek ve hesabınıza erişebilmek
            için lütfen e-mailinizi doğrulayınız.
          </div>
        </div>
      </div>

      <div className="p-[24px] pb-[32px] border-t border-t-greyscale-100">
        <div
          className="p-[16px] flex justify-center rounded-[100px] text-lg font-semibold relative overflow-hidden bg-dark-100 text-white"
          onClick={onClick}
        >
          Hesabımı Doğrula
        </div>
      </div>
    </div>
  )
}

export default VerifyAccount
