import React, { useState } from 'react'
import cx from 'classnames'

import { Input } from 'lib/components/Input'
import { ValidationError } from 'lib/types/errors'
import { post } from 'lib/api'
import toast from 'react-hot-toast'
import { AxiosError } from 'axios'
import getError from 'lib/utils/getError'

type Props = {
  phoneNumber: string

  onSuccess: () => void
}

const NewPassword: React.FC<Props> = ({ phoneNumber, onSuccess }) => {
  const [password, setPassword] = useState('')
  const [passwordAgain, setPasswordAgain] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<ValidationError[]>([])

  const buttonDisabled = !password || !passwordAgain

  const onPasswordChange = (value: string) => {
    setPassword(value)
  }

  const onPasswordAgainChange = (value: string) => {
    setPasswordAgain(value)
  }

  const onSubmit = async () => {
    setLoading(true)

    try {
      await post('/reset-password', {
        password,
        phone_number: phoneNumber,
        password_confirmation: passwordAgain,
      })

      toast.dismiss()

      toast.success('Şifreniz başarıyla güncellendi.')

      setTimeout(() => {
        onSuccess()
      }, 1500)
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          errors: ValidationError[]
        }

        if (!data.errors) {
          toast.dismiss()

          toast.error('Bir hata oluştu')

          return
        }

        const validationErrors = data.errors.filter(
          (error) => error.field !== 'general'
        )

        const generalError = data.errors.find(
          (error) => error.field === 'general'
        )

        setErrors(validationErrors)

        if (generalError) {
          toast.dismiss()

          toast.error(generalError.message)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col h-full">
      <div className="pt-[32px] px-[24px] overflow-scroll flex-1">
        <div className="mb-[32px]">
          <div className="text-greyscale-900 text-h3 font-semibold mb-[16px]">
            Yeni şifreni gir 🔐
          </div>
          <div className="text-greyscale-700 text-h6 font-medium">
            Lütfen yeni şifreniz, bir öncekinden farklı olsun
          </div>
        </div>

        <div className="flex flex-col gap-[28px]">
          <div className="flex flex-col gap-[24px]">
            <Input
              value={password}
              onChange={onPasswordChange}
              placeholder="Yeni şifre"
              label="Yeni şifre"
              htmlType="password"
              error={getError(errors, 'password')}
            />

            <Input
              value={passwordAgain}
              onChange={onPasswordAgainChange}
              placeholder="Yeni şifre tekrar"
              label="Yeni şifre tekrar"
              htmlType="password"
              error={getError(errors, 'password_confirmation')}
            />
          </div>
        </div>
      </div>

      <div className="p-[24px] pb-[32px] border-t border-t-greyscale-100">
        <div
          className={cx(
            'p-[16px] flex justify-center rounded-[100px] text-lg font-semibold relative overflow-hidden',
            {
              'bg-dark-500 text-greyscale-700': buttonDisabled || loading,
              'bg-dark-100 text-white': !buttonDisabled && !loading,
            }
          )}
          onClick={onSubmit}
        >
          Şifremi Sıfırla
        </div>
      </div>
    </div>
  )
}

export default NewPassword
