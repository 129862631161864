import { AxiosError } from 'axios'
import { get, postWithAuth } from 'lib/api'
import toast from 'react-hot-toast'
import { NavigateFunction } from 'react-router-dom'

type Payload = {
  token: string
  userId: number
  navigate: NavigateFunction
  capsule: string
}

export const onConnectCapsule = async (payload: Payload) => {
  try {
    const { data } = await get(`/capsules/get-capsule/${payload.capsule}`)

    if (!data.sender_id) {
      try {
        await postWithAuth(`/capsules/connect`, {
          uuid: payload.capsule,
        })

        payload.navigate('/success')
      } catch (e) {
        const error = e as AxiosError

        if (error.response) {
          const data = error.response.data as {
            error: {
              status: number
              message: string
            }
          }

          toast.error(data.error.message)
        }
      }

      return
    }

    if (data.sender_id === payload.userId) {
      payload.navigate('/success')

      return
    }

    if (!data.receiver_id && data.locked) {
      try {
        await postWithAuth(`/capsules/connect`, {
          uuid: payload.capsule,
        })

        payload.navigate(`/${payload.capsule}`)
      } catch (e) {
        const error = e as AxiosError

        if (error.response) {
          const data = error.response.data as {
            error: {
              status: number
              message: string
            }
          }

          toast.error(data.error.message)
        }
      }

      return
    }

    if (data.locked && data.receiver_id === payload.userId) {
      payload.navigate(`/${payload.capsule}`)

      return
    }

    payload.navigate('/404')
  } catch (e) {
    const error = e as AxiosError

    if (error.response) {
      const data = error.response.data as {
        error: {
          status: number
          message: string
        }
      }

      toast.error(data.error.message)
    }
  }
}
