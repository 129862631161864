import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import IconInfoCircleBold from 'lib/icons/IconInfoCircleBold'
import { Modal } from '../Modal'

const CheckIcon = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0607 0.93934C12.6464 1.52513 12.6464 2.47487 12.0607 3.06066L6.06066 9.06066C5.47487 9.64645 4.52513 9.64645 3.93934 9.06066L0.93934 6.06066C0.353553 5.47487 0.353553 4.52513 0.93934 3.93934C1.52513 3.35355 2.47487 3.35355 3.06066 3.93934L5 5.87868L9.93934 0.93934C10.5251 0.353553 11.4749 0.353553 12.0607 0.93934Z"
      fill="white"
    />
  </svg>
)

export type ModalProps = {
  source: string
  title: string
}

type Props = {
  error?: string

  onChange: (value: boolean) => void
}

const Agreements: React.FC<Props> = ({ error, onChange }) => {
  const [modal, setModal] = useState<ModalProps | null>(null)
  const [isAgreementChecked, setIsAgreementChecked] = useState(false)
  const [isKvkkChecked, setIsKvkkChecked] = useState(false)
  const [isCookieChecked, setIsCookieChecked] = useState(false)

  useEffect(() => {
    onChange(isAgreementChecked && isKvkkChecked && isCookieChecked)
  }, [isAgreementChecked, isKvkkChecked, isCookieChecked])

  return (
    <div className="flex flex-col gap-[10px]">
      <div
        className="flex gap-[16px] items-center"
        onClick={() => setIsAgreementChecked(!isAgreementChecked)}
      >
        <div
          className={cx(
            'w-[24px] h-[24px] rounded-[8px] border-[3px] border-dark-500 items-center justify-center flex',
            {
              'bg-dark-500': isAgreementChecked,
            }
          )}
        >
          {isAgreementChecked && <CheckIcon />}
        </div>
        <div className="text-greyscale-900 text-md font-regular">
          <span
            className="font-semibold underline cursor-pointer"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setModal({
                source: '/uyelik_sozlesmesi.pdf#toolbar=0',
                title: 'Açık Rıza Metni',
              })
            }}
          >
            Açık rıza metnini
          </span>{' '}
          okudum, onaylıyorum.
        </div>
      </div>
      <div
        className="flex gap-[16px] items-center"
        onClick={() => setIsKvkkChecked(!isKvkkChecked)}
      >
        <div
          className={cx(
            'w-[24px] h-[24px] rounded-[8px] border-[3px] border-dark-500 items-center justify-center flex',
            {
              'bg-dark-500': isKvkkChecked,
            }
          )}
        >
          {isKvkkChecked && <CheckIcon />}
        </div>
        <div className="text-greyscale-900 text-md font-regular">
          <span
            className="font-semibold underline cursor-pointer"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setModal({
                source: '/kvkk.pdf#toolbar=0',
                title: 'KVKK',
              })
            }}
          >
            KVKK metnini
          </span>{' '}
          okudum, onaylıyorum.
        </div>
      </div>
      <div
        className="flex gap-[16px] items-center"
        onClick={() => setIsCookieChecked(!isCookieChecked)}
      >
        <div
          className={cx(
            'w-[24px] h-[24px] rounded-[8px] border-[3px] border-dark-500 items-center justify-center flex',
            {
              'bg-dark-500': isCookieChecked,
            }
          )}
        >
          {isCookieChecked && <CheckIcon />}
        </div>

        <div className="text-greyscale-900 text-md font-regular">
          <span
            className="font-semibold underline cursor-pointer"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setModal({
                source: '/cerez_politikasi.pdf#toolbar=0',
                title: 'Çerez politikası',
              })
            }}
          >
            Çerez politikası metnini
          </span>{' '}
          okudum, onaylıyorum.
        </div>
      </div>

      {error && (
        <div className="flex items-center bg-transparent-red px-[12px] py-[8px] rounded-[8px] text-error">
          <div className="flex mr-[8px]">
            <IconInfoCircleBold size={18} />
          </div>

          <div className="text-medium">{error}</div>
        </div>
      )}

      {modal && <Modal modal={modal} setModal={setModal} />}
    </div>
  )
}

export default Agreements
