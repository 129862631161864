import { AxiosError } from 'axios'
import { get, postWithAuth } from 'lib/api'
import toast from 'react-hot-toast'
import { NavigateFunction } from 'react-router-dom'

type Payload = {
  token: string
  userId: number
  navigate: NavigateFunction
  code: string
}

export const onConnectEvent = async (payload: Payload) => {
  try {
    const { data } = await get(`/event/get-event/${payload.code}`)

    if (data.event_owner_id === payload.userId) {
      payload.navigate(`/event/${payload.code}`)

      return
    }

    try {
      await postWithAuth(`/event/connect`, {
        code: payload.code,
      })

      payload.navigate(`/event/${payload.code}`)
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.error(data.error.message)
      }
    }
  } catch (e) {
    const error = e as AxiosError

    if (error.response) {
      const data = error.response.data as {
        error: {
          status: number
          message: string
        }
      }

      toast.error(data.error.message)
    }
  }
}
