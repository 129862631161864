import axios, { AxiosRequestConfig } from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const get = async (url: string, params?: AxiosRequestConfig<any>) => {
  const response = await instance.get(url, params)

  return response.data
}

export const getWithAuth = async (url: string) => {
  const token = localStorage.getItem('token')

  if (!token) {
    throw new Error('Token not found')
  }

  const response = await instance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return response.data
}

export const post = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig<any>
) => {
  const response = await instance.post(url, data, config)

  return response.data
}

export const postWithAuth = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig<any>
) => {
  const token = localStorage.getItem('token')

  if (!token) {
    throw new Error('Token not found')
  }

  const response = await instance.post(url, data, {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...config?.headers,
    },
  })

  return response.data
}

export const put = async (url: string, data?: any) => {
  const response = await instance.put(url, data)

  return response.data
}

export const patch = async (url: string, data?: any) => {
  const response = await instance.patch(url, data)

  return response.data
}

export const del = async (url: string) => {
  const response = await instance.delete(url)

  return response.data
}
