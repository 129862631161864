import { Route, Routes as RoutesBase } from 'react-router-dom'
import React from 'react'
import type { AppState } from 'store'

import { Capsule } from 'components/Capsule'
import { Login } from 'components/Login'
import { Page404 } from 'components/Page404'
import { Register } from 'components/Register'
import { Success } from 'components/Success'
import { useSelector } from 'react-redux'
import { InitialLoading } from 'components/InitialLoading'
import { OTP } from 'components/OTP'
import { Connect } from 'components/Connect'
import { ForgotPassword } from 'components/ForgotPassword'
import { Event } from 'components/Event'
import { EventDetail } from 'components/EventDetail'
import { EventUpload } from 'components/EventUpload'

const Routes: React.FC = () => {
  const loading = useSelector<AppState, boolean>(({ loading }) => loading)

  if (loading) {
    return <InitialLoading />
  }

  return (
    <RoutesBase>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/success" element={<Success />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/connect" element={<Connect />} />
      <Route path="/event" element={<Event />} />
      <Route path="/event/upload" element={<EventUpload />} />
      <Route path="/event/:code" element={<EventDetail />} />
      <Route path="/:uuid" element={<Capsule />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/" element={<Login />} />
    </RoutesBase>
  )
}

export default Routes
