export const dateAndTimeDiff = (date1: Date, date2: Date) => {
  const diff = date1.getTime() - date2.getTime()

  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((diff / 1000 / 60) % 60)
  const seconds = Math.floor((diff / 1000) % 60)

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}
