import React from 'react'

type Props = {
  onClose: () => void
}

const UserGuide: React.FC<Props> = ({ onClose }) => {
  const onClick = () => {
    localStorage.setItem('userGuide', 'true')

    onClose()
  }

  return (
    <div className="absolute top-0 left-0 w-full h-full z-[99999999999] bg-[#000] flex flex-col">
      <div className="flex justify-end px-[20px]" onClick={onClick}>
        <svg
          width="38"
          height="39"
          viewBox="0 0 38 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.9881 31.3644C25.7513 31.3644 31.3336 25.7821 31.3336 19.0308C31.3336 12.2796 25.7394 6.69727 18.9762 6.69727C12.2249 6.69727 6.65454 12.2796 6.65454 19.0308C6.65454 25.7821 12.2369 31.3644 18.9881 31.3644ZM18.9881 28.9072C13.5131 28.9072 9.13557 24.5058 9.13557 19.0308C9.13557 13.5559 13.5131 9.16636 18.9762 9.16636C24.4511 9.16636 28.8526 13.5559 28.8645 19.0308C28.8764 24.5058 24.4631 28.9072 18.9881 28.9072ZM15.0757 24.0406C15.3858 24.0406 15.6602 23.9332 15.863 23.7185L18.9881 20.5934L22.1132 23.7185C22.316 23.9213 22.5784 24.0406 22.9005 24.0406C23.5088 24.0406 23.9859 23.5635 23.9859 22.9551C23.9859 22.6569 23.8667 22.3945 23.6639 22.1918L20.5268 19.0547L23.6639 15.9057C23.8905 15.6791 23.9979 15.4405 23.9979 15.1542C23.9979 14.5459 23.5208 14.0688 22.9124 14.0688C22.6142 14.0688 22.3637 14.1642 22.1371 14.3908L18.9881 17.5279L15.8391 14.4028C15.6363 14.1881 15.3858 14.0807 15.0757 14.0807C14.4674 14.0807 13.9903 14.5459 13.9903 15.1542C13.9903 15.4524 14.1095 15.7148 14.3243 15.9176L17.4494 19.0547L14.3243 22.2037C14.1095 22.3945 13.9903 22.6689 13.9903 22.9551C13.9903 23.5635 14.4674 24.0406 15.0757 24.0406Z"
            fill="#fff"
          />
        </svg>
      </div>
      <iframe
        src="https://www.youtube.com/embed/i908RguG-yI?si=r3x_VsFNkvaxqaN2"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        style={{
          width: '100%',
          height: 'calc(100vh - 100px)',
        }}
      />
    </div>
  )
}

export default UserGuide
