import React from 'react'

import LogoSmall from '../../lib/images/logo-small.png'

const Page404: React.FC = () => (
  <div>
    <div className="flex items-center justify-center w-full h-[72px]">
      <img src={LogoSmall} alt="logo" />
    </div>

    <div className="flex flex-col justify-center gap-[48px] absolute w-full h-full">
      <div className="px-[24px] py-[12px] flex text-[40px] leading-[56px] font-bold">
        Böyle bir sayfa <br /> bulunamadı!
      </div>

      <div>
        <svg
          viewBox="0 0 430 187"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-auto"
        >
          <path
            d="M138.029 149.185H118.417V184.354H70.8649V149.185H0V113.26L74.0103 2.64844H118.417V112.504H138.029V149.185ZM70.8649 112.504V91.3269C70.8649 87.3562 71.0499 82.062 71.2349 75.2551C71.605 68.4482 71.79 65.0448 71.9751 64.6666H70.6799C67.9045 71.0954 64.759 76.9568 61.2435 82.6292L41.6308 112.504H70.8649Z"
            fill="#EEEEEE"
          />
          <path
            d="M279.019 93.7836C279.019 125.927 273.653 149.562 263.292 164.499C252.746 179.437 236.648 187 214.815 187C193.537 187 177.625 179.248 166.709 163.554C155.977 147.86 150.611 124.604 150.611 93.7836C150.611 61.451 155.792 37.816 166.524 22.6896C177.07 7.5632 193.167 0 215 0C236.093 0 252.191 7.94135 262.922 23.635C273.654 39.3286 279.019 62.5854 279.019 93.7836ZM198.348 93.7836C198.348 114.393 199.643 128.574 202.048 136.138C204.639 143.89 208.709 147.671 214.63 147.671C220.551 147.671 224.807 143.701 227.397 135.759C229.802 127.818 231.098 113.826 231.098 93.7836C231.098 73.5521 229.802 59.5602 227.397 51.4297C224.807 43.2993 220.736 39.3286 214.815 39.3286C208.894 39.3286 204.639 43.2993 202.234 51.0516C199.643 58.8038 198.348 72.9848 198.348 93.7836Z"
            fill="#E0E0E0"
          />
          <path
            d="M430 149.184H410.387V184.353H362.836V149.184H291.971V113.259L365.981 2.64746H410.387V112.503H430V149.184ZM363.021 112.503V91.3259C363.021 87.3552 363.206 82.061 363.391 75.2541C363.761 68.4473 363.946 65.0438 364.131 64.6657H362.836C360.06 71.0944 356.915 76.9558 353.399 82.6282L333.602 112.692H363.021V112.503Z"
            fill="#EEEEEE"
          />
        </svg>
      </div>
    </div>
  </div>
)

export default Page404
