import cx from 'classnames'
import React, { useRef, useState } from 'react'

import IconLockBold from 'lib/icons/IconLockBold'
import IconMessageBold from 'lib/icons/IconMessageBold'
import IconHideBold from 'lib/icons/IconHideBold'
import IconShowBold from 'lib/icons/IconShowBold'
import IconInfoCircleBold from 'lib/icons/IconInfoCircleBold'

type Props = {
  value: string
  label?: string
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  htmlType?: React.InputHTMLAttributes<HTMLInputElement>['type']
  defaultValue?: string
  placeholder?: string
  error?: string
  disabled?: boolean

  onChange: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

const Input: React.FC<Props> = (props) => {
  const ref = useRef<HTMLInputElement>(null)

  const [type, setType] = useState<
    React.InputHTMLAttributes<HTMLInputElement>['type']
  >(props.htmlType || 'text')

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value, event)
  }

  const onSwitchPasswordType = () => {
    if (type === 'password') {
      setType('text')
    } else {
      setType('password')
    }

    if (ref.current) {
      ref.current.focus()

      setTimeout(() => {
        ref.current?.setSelectionRange(
          ref.current.value.length,
          ref.current.value.length
        )
      })
    }
  }

  const iconColor =
    props.value || props.defaultValue
      ? 'text-greyscale-900'
      : 'text-greyscale-500'

  return (
    <div
      className={cx('flex flex-col gap-[8px]', {
        'opacity-[0.5]': props.disabled,
      })}
    >
      {props.label && (
        <div className="flex text-h6 font-semibold text-greyscale-900">
          {props.label}
        </div>
      )}

      <div
        className={cx(
          'flex items-center gap-[12px] h-[65px] rounded-[12px] px-[20px]',
          {
            'bg-white border-[2px] border-error': props.error,
            'bg-greyscale-50 border-[2px] border-greyscale-50': !props.error,
          }
        )}
      >
        {props.leftIcon && (
          <div className={cx('flex', iconColor)} children={props.leftIcon} />
        )}

        {!props.leftIcon && props.htmlType === 'password' && (
          <div className={cx('flex', iconColor)}>
            <IconLockBold size={20} />
          </div>
        )}

        {!props.leftIcon && props.htmlType === 'email' && (
          <div className={cx('flex', iconColor)}>
            <IconMessageBold size={20} />
          </div>
        )}

        <div className="flex-1">
          <input
            className="h-full w-full bg-transparent text-h6 font-regular placeholder:text-greyscale-500 text-greyscale-900 focus:outline-none"
            type={type}
            value={props.value}
            defaultValue={props.defaultValue}
            onChange={onChange}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            ref={ref}
          />
        </div>

        {props.rightIcon && (
          <div className={cx('flex', iconColor)} children={props.rightIcon} />
        )}

        {!props.rightIcon && props.htmlType === 'password' && (
          <div
            className={cx('flex', {
              [iconColor]: type === 'password',
              'text-greyscale-900': type === 'text',
            })}
            onClick={onSwitchPasswordType}
          >
            {type === 'password' ? (
              <IconHideBold size={20} />
            ) : (
              <IconShowBold size={20} />
            )}
          </div>
        )}
      </div>

      {props.error && (
        <div className="flex items-center bg-transparent-red px-[12px] py-[8px] rounded-[8px] text-error">
          <div className="flex mr-[8px]">
            <IconInfoCircleBold size={18} />
          </div>

          <div className="text-medium">{props.error}</div>

          {/* <div className="underline font-medium ml-[8px] cursor-pointer">
            Doğrula
          </div> */}
        </div>
      )}
    </div>
  )
}

export default Input
