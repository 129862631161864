import { Input } from 'lib/components/Input'
import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { get } from 'lib/api'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { UserState } from 'lib/types/models'
import { useNavigate } from 'react-router-dom'

const Event: React.FC = () => {
  const params = new URLSearchParams(window.location.search)

  const ref = useRef<HTMLDivElement>(null)

  const [code, setCode] = useState(params.get('code') || '')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const user = useSelector<AppState, UserState>(({ user }) => user)

  const onSubmit = async () => {
    setLoading(true)

    try {
      const { data } = await get(`/event/get-event/${code}`)

      if (!user.authenticated) {
        if (!data.eventOwnerId) {
          navigate(`/register?event=${code}`)

          return
        }

        if (!data.locked) {
          localStorage.setItem('event', code)

          navigate(`/event/upload?uuid=${data.uuid}`)

          return
        }

        if (data.locked) {
          navigate(`/login?event=${code}`)

          return
        }
      }

      if (user.authenticated) {
        if (!data.locked) {
          localStorage.setItem('event', code)

          navigate(`/event/upload?uuid=${data.uuid}`)

          return
        }

        navigate(`/login?event=${code}`)
      }
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.error(data.error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (!ref.current) {
        return
      }

      ref.current.style.opacity = '1'
    }, 100)
  }, [ref])

  useEffect(() => {
    if (!params.get('code')) {
      return
    }

    onSubmit()
  }, [])

  return (
    <div
      className="flex flex-col h-full opacity-0 transition-opacity duration-[250ms]"
      ref={ref}
    >
      <div className="pt-[32px] px-[24px] overflow-scroll flex-1">
        <div className="mb-[32px]">
          <div className="text-greyscale-900 text-h3 font-semibold">
            TextToNext Event
          </div>
        </div>
        <div className="flex flex-col gap-[28px]">
          <div className="flex flex-col gap-[24px]">
            <Input
              value={code}
              onChange={setCode}
              label="Etkinlik kodu"
              placeholder="Etkinlik kodu"
            />
          </div>
        </div>
      </div>
      <div className="p-[24px] pb-[32px] border-t border-t-greyscale-100">
        <div
          className={cx(
            'p-[16px] flex justify-center rounded-[100px] text-lg font-semibold relative overflow-hidden',
            {
              'bg-dark-500 text-greyscale-700': !code || loading,
              'bg-dark-100 text-white': !!code && !loading,
            }
          )}
          onClick={onSubmit}
        >
          {loading && (
            <div className="bg-dark-500 absolute top-0 right-0 bottom-0 left-0 z-10" />
          )}
          {loading && (
            <div className="loading absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] z-[20]">
              <span />
              <span />
              <span />
            </div>
          )}
          Devam Et
        </div>
      </div>
    </div>
  )
}

export default Event
