import type { ValidationError } from 'lib/types/errors'

export default (errors: ValidationError[], field: string) => {
  const error = errors.find((error) => error.field === field)

  if (error) {
    return error.message
  }

  return ''
}
