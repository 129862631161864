import React, { useState } from 'react'

import { post } from 'lib/api'
import { ValidationError } from 'lib/types/errors'
import cx from 'classnames'
import getError from 'lib/utils/getError'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { NewPassword } from 'components/NewPassword'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Input } from 'lib/components/Input'
import { OTP } from './components/OTP'

const ForgotPassword: React.FC = () => {
  const [searchParams] = useSearchParams()

  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [status, setStatus] = useState<'idle' | 'otp' | 'reset'>('idle')

  const navigate = useNavigate()

  const onSubmit = async () => {
    setLoading(true)

    try {
      const { data } = await post('/forgot-password', { email })

      setPhoneNumber(data.phone_number)

      setStatus('otp')
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          errors: ValidationError[]
        }

        const validationErrors = data.errors.filter(
          (error) => error.field !== 'general'
        )

        const generalError = data.errors.find(
          (error) => error.field === 'general'
        )

        setErrors(validationErrors)

        if (generalError) {
          toast.dismiss()

          toast.error(generalError.message)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  if (status === 'otp') {
    return (
      <OTP phoneNumber={phoneNumber} onSuccess={() => setStatus('reset')} />
    )
  }

  if (status === 'reset') {
    let url = `/login?capsule=${searchParams.get('capsule')}`

    if (searchParams.get('event')) {
      url = `/login?capsule=${searchParams.get('event')}`
    }

    return (
      <NewPassword phoneNumber={phoneNumber} onSuccess={() => navigate(url)} />
    )
  }

  return (
    <div className="flex flex-col h-full">
      <div className="pt-[32px] px-[24px] overflow-scroll flex-1">
        <div className="mb-[32px]">
          <div className="text-greyscale-900 text-h3 font-semibold mb-[16px]">
            Şifreni sıfırla 🔑
          </div>
          <div className="text-greyscale-700 text-h6 font-medium">
            Lütfen telefon numaranızı girin. Şifrenizi sıfırlamak için size tek
            kullanımlık bir kod göndereceğiz.
          </div>
        </div>

        <div className="flex flex-col gap-[28px]">
          <div className="flex flex-col gap-[24px]">
            <Input
              value={email}
              onChange={setEmail}
              placeholder="E-posta adresi"
              label="E-posta adresi"
              htmlType="email"
              error={getError(errors, 'email')}
            />
          </div>
        </div>
      </div>

      <div className="p-[24px] pb-[32px] border-t border-t-greyscale-100">
        <div
          className={cx(
            'p-[16px] flex justify-center rounded-[100px] text-lg font-semibold relative overflow-hidden',
            {
              'bg-dark-500 text-greyscale-700': !email || loading,
              'bg-dark-100 text-white': email && !loading,
            }
          )}
          onClick={onSubmit}
        >
          {loading && (
            <div className="bg-dark-500 absolute top-0 right-0 bottom-0 left-0 z-10" />
          )}
          {loading && (
            <div className="loading absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] z-[20]">
              <span />
              <span />
              <span />
            </div>
          )}
          Devam Et
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
