import React from 'react'

type Props = {
  size?: number
}

const IconImageBold: React.FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2216 29.8327H21.7771C26.2975 29.8327 29.3333 26.6619 29.3333 21.9437V11.055C29.3333 6.33683 26.2975 3.16602 21.7784 3.16602H10.2216C5.70253 3.16602 2.66667 6.33683 2.66667 11.055V21.9437C2.66667 26.6619 5.70253 29.8327 10.2216 29.8327ZM11.3318 15.1657C9.49362 15.1657 8.00001 13.6701 8.00001 11.8324C8.00001 9.99466 9.49362 8.49902 11.3318 8.49902C13.1687 8.49902 14.6636 9.99466 14.6636 11.8324C14.6636 13.6701 13.1687 15.1657 11.3318 15.1657ZM26.4278 20.4111C26.8742 21.5559 26.6423 22.9319 26.165 24.0657C25.5992 25.4142 24.5158 26.4352 23.1509 26.881C22.5449 27.0791 21.9094 27.1658 21.2752 27.1658H10.0382C8.92 27.1658 7.93051 26.8975 7.11934 26.398C6.61119 26.0843 6.52136 25.3605 6.89812 24.8913C7.52828 24.107 8.15039 23.3199 8.77787 22.526C9.97384 21.0069 10.7796 20.5666 11.6753 20.9532C12.0386 21.1128 12.4033 21.3523 12.7787 21.6054C13.7789 22.2852 15.1693 23.2195 17.0008 22.2054C18.2541 21.5034 18.9811 20.2993 19.6141 19.2508L19.6247 19.2332C19.6696 19.1597 19.714 19.0861 19.7584 19.0127C19.9711 18.6607 20.181 18.3134 20.4184 17.9935C20.7161 17.5931 21.8195 16.3409 23.2488 17.2325C24.1592 17.794 24.9248 18.5535 25.744 19.3667C26.0564 19.6777 26.2789 20.0313 26.4278 20.4111Z"
      fill="currentColor"
    />
  </svg>
)

export default IconImageBold
