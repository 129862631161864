import { AxiosError } from 'axios'
import { postWithAuth } from 'lib/api'
import React, { useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import Lottie from 'react-lottie'
import Logo from '../../lib/images/logo-full.png'
import animationData from '../../animations/waiting.json'

const TIME = 4000

const Connect: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const connectCapsule = async () => {
    try {
      const { data } = await postWithAuth('/capsules/connect', {
        uuid: searchParams.get('capsule'),
      })

      if (!data.sender_id) {
        navigate('/success')

        return
      }

      navigate(`/${data.uuid}`)
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.dismiss()

        toast.error(data.error.message)
      }
    }
  }

  const connectEvent = async () => {
    try {
      await postWithAuth(`/event/connect`, {
        code: searchParams.get('event'),
      })

      navigate(`/event/${searchParams.get('event')}`)
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.error(data.error.message)
      }
    }
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }

    setTimeout(() => {
      const container = ref.current as HTMLDivElement

      container.style.opacity = '0'
    }, TIME)

    setTimeout(() => {
      if (searchParams.get('capsule')) {
        connectCapsule()
      }
      if (searchParams.get('event')) {
        connectEvent()
      }
    }, TIME + 250)
  }, [])

  return (
    <div
      className="flex flex-col items-center justify-center h-full transition-opacity duration-[250ms]"
      ref={ref}
    >
      <div className="w-full absolute top-[30px] left-0 flex justify-center">
        <img src={Logo} alt="logo" width={200} />
      </div>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData,
        }}
        height={200}
        width={200}
      />

      <div className="text-[20px] leading-[1.6] text-dark-500 font-medium">
        {searchParams.get('capsule') && 'Seni Kapsülünle Eşliyoruz'}
        {searchParams.get('event') && `Seni Event'e Bağlıyoruz`}
      </div>
    </div>
  )
}

export default Connect
