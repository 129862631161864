import React from 'react'
import { Provider } from 'react-redux'
import store from 'store'
import { Toaster } from 'react-hot-toast'

import { Routes } from 'components/Routes'

const App: React.FC = () => (
  <Provider store={store}>
    <Routes />

    <Toaster position="bottom-center" reverseOrder={false} />
  </Provider>
)

export default App
