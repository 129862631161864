import { AxiosError } from 'axios'
import { post } from 'lib/api'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import cx from 'classnames'

type Props = {
  phone: string
  type: string

  onSuccess: () => void
}

const OTPResendButton: React.FC<Props> = ({ phone, type, onSuccess }) => {
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)

    try {
      await post('/otp/resend', {
        phone_number: phone,
        type,
      })

      onSuccess()
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.dismiss()

        toast.error(data.error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      className={cx(
        'p-[16px] flex justify-center rounded-[100px] text-lg font-semibold relative overflow-hidden',
        {
          'bg-dark-500 text-greyscale-700': loading,
          'bg-dark-100 text-white': !loading,
        }
      )}
      onClick={onClick}
    >
      {loading && (
        <div className="bg-dark-500 absolute top-0 right-0 bottom-0 left-0 z-10" />
      )}
      {loading && (
        <div className="loading absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] z-[20]">
          <span />
          <span />
          <span />
        </div>
      )}
      Kodu yeniden gönder
    </div>
  )
}

export default OTPResendButton
