import { AxiosError } from 'axios'
import { getWithAuth } from 'lib/api'
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Navigate, useParams } from 'react-router-dom'
import { Success } from 'components/Success'
import Lottie from 'react-lottie'
import Logo from '../../lib/images/logo-full.png'
import animationData from '../../animations/connecting-2.json'

const TIME = 2000

const EventDetail: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<{
    isOwner: boolean
    isParticipant: boolean
  }>({
    isOwner: false,
    isParticipant: false,
  })

  const params = useParams()

  const fetchEvent = async () => {
    try {
      const { data } = await getWithAuth(`/event/check/${params.code}`)

      setData(data)
      setLoading(false)
    } catch (e) {
      const error = e as AxiosError

      if (error.response) {
        const data = error.response.data as {
          error: {
            status: number
            message: string
          }
        }

        toast.error(data.error.message)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (!ref.current) {
        return
      }

      ref.current.style.opacity = '1'
    }, 100)

    setTimeout(() => {
      if (!ref.current) {
        return
      }

      ref.current.style.opacity = '0'
    }, TIME + 200)

    setTimeout(() => {
      fetchEvent()
    }, TIME + 350)
  }, [])

  if (loading) {
    return (
      <div
        className="flex flex-col items-center justify-center h-full opacity-0 transition-opacity duration-[250ms]"
        ref={ref}
      >
        <div className="w-full absolute top-[30px] left-0 flex justify-center">
          <img src={Logo} alt="logo" width={200} />
        </div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
          }}
          height={200}
          width={200}
        />

        <div className="text-[20px] leading-[1.6] text-dark-500 font-medium">
          Ürününle Bağlantı Sağlanıyor
        </div>
      </div>
    )
  }

  if (data.isOwner || data.isParticipant) {
    return <Success />
  }

  return <Navigate to="/404" />
}

export default EventDetail
