import React from 'react'

type Props = {
  size?: number
}

const IconVoiceBold: React.FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.233 20.789H15.7667C12.5622 20.789 9.96477 18.224 9.96477 15.0608V8.89644C9.96477 5.73198 12.5622 3.16699 15.7667 3.16699H16.233C18.8925 3.16699 21.1334 4.93225 21.8193 7.34163C21.9136 7.67198 21.6643 7.99978 21.3181 7.99978H19.8082C19.3523 7.99978 18.9816 8.36456 18.9816 8.81481V8.81608C18.9816 9.2676 19.3523 9.63239 19.8082 9.63239H21.2006C21.6617 9.63239 22.0363 10.001 22.0363 10.4563C22.0363 10.9117 21.6617 11.2803 21.2006 11.2803H19.8082C19.3523 11.2803 18.9816 11.6464 18.9816 12.0979C18.9816 12.5481 19.3523 12.9142 19.8082 12.9142H21.2006C21.6617 12.9142 22.0363 13.2828 22.0363 13.7394C22.0363 14.1935 21.6617 14.5621 21.2006 14.5621H19.8082C19.3523 14.5621 18.9816 14.9282 18.9816 15.3797C18.9816 15.8299 19.3523 16.1947 19.8082 16.1947H21.2445C21.5984 16.1947 21.8516 16.5365 21.7405 16.8682C20.9746 19.1462 18.7995 20.789 16.233 20.789ZM24.7501 14.8774C24.7501 14.1721 25.3287 13.6019 26.0417 13.6019C26.7547 13.6019 27.3333 14.1721 27.3333 14.8774C27.3333 20.6158 22.9341 25.3542 17.2923 25.9906V28.5582C17.2923 29.2622 16.7136 29.8337 16.0006 29.8337C15.2864 29.8337 14.709 29.2622 14.709 28.5582V25.9906C9.06593 25.3542 4.66666 20.6158 4.66666 14.8774C4.66666 14.1721 5.24531 13.6019 5.95829 13.6019C6.67126 13.6019 7.24991 14.1721 7.24991 14.8774C7.24991 19.6413 11.1751 23.5175 16.0006 23.5175C20.8249 23.5175 24.7501 19.6413 24.7501 14.8774Z"
      fill="currentColor"
    />
  </svg>
)

export default IconVoiceBold
